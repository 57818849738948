$(function(){
    $('[data-nav-trigger]').on('click', function(){
        $(this).toggleClass('active');
        $('[data-nav]').slideToggle(200);
        $('body').toggleClass('active');
    });

    $('[data-toggle]').on('click', function(){
        const target = $(this).data('toggle');
        $('[data-toggle-target=' + target + ']').slideToggle(200);
        return false;
    });
    
    $('.p-gnav-list-item a').on('click', function(){
        $('[data-nav-trigger]').removeClass('active');
        $('[data-nav]').slideToggle(200);
        $('body').removeClass('active');
    });
});
